type OptimizeImageProps = {
  src?: string;
  level?: number;
  maxWidth?: number;
};

export const optimizeImage = ({
  src = "",
  level = 60,
  maxWidth,
}: OptimizeImageProps) => {
  const dimensionsPath = maxWidth ? `${maxWidth}x0/` : "";
  const optimizedUrl = `${src}/m/${dimensionsPath}filters:quality(${level})`;
  return optimizedUrl;
};
