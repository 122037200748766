import { useMemo } from "react";
import {
  faCheck,
  faPlus,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PhotoTaken, PhotoTakenState } from "~/api/models/UploadPhotos";
import photoLibrary from "~/assets/svg/photoLibrary.svg";
import retakePhoto from "~/assets/svg/retakePhoto.svg";
import takePhoto from "~/assets/svg/takePhoto.svg";
import Copy from "~/components/atom/Copy";
import Popover, { PopoverTrigger } from "~/components/atoms/Popover/Popover";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import { PhotoPosition } from "~/modules/upload-photo/upload-photo";
import { optimizeImage } from "~/utils/storyblok.image.utils";

type UploadOverviewItem = {
  item: PhotoPosition;
  handleGoToTakePhoto: () => void;
  handleOpenCameraRoll: () => void;
  photoTaken?: PhotoTaken;
  isLoading?: boolean;
};

const UploadOverviewItem = ({
  item,
  handleGoToTakePhoto,
  handleOpenCameraRoll,
  photoTaken,
  isLoading,
}: UploadOverviewItem) => {
  const shouldShowImagePreview = useMemo(() => {
    return photoTaken && photoTaken.state !== PhotoTakenState.WAITING;
  }, [photoTaken]);

  const PopoverItem = () => (
    <PopoverTrigger>
      <div
        className="rounded-xl py-1 pl-1 pr-4 gap-4 w-full border border-dashed border-grey-50 inline-flex items-center justify-center outline-none"
        aria-label={`Position ${item.title}`}
      >
        {isLoading ? (
          <div className="w-[68px] h-[80px] flex items-center justify-center">
            <SpinnerIcon className="text-3xl" />
          </div>
        ) : (
          <img
            src={
              photoTaken?.src ??
              optimizeImage({ src: item.imageUrl, maxWidth: 1500 })
            }
            alt={item.imageAlt}
            className="w-[68px] h-[80px] rounded-md object-cover"
          />
        )}
        <p className="flex-1 text-lg text-left flex gap-2 flex-row items-center">
          {item.title}
          {shouldShowImagePreview && (
            <span className="flex items-center justify-center w-4 h-4 bg-green-900 rounded-full">
              <FontAwesomeIcon icon={faCheck} className="text-white text-2xs" />
            </span>
          )}
        </p>
        {shouldShowImagePreview ? (
          <FontAwesomeIcon
            icon={faEllipsisH}
            className="text-grey-800 text-base"
          />
        ) : (
          <FontAwesomeIcon icon={faPlus} className="text-grey-800 text-sm" />
        )}
      </div>
    </PopoverTrigger>
  );

  const PopoverContent = () => (
    <div className="bg-white mr-2 border border-grey-100 rounded-md shadow-md will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade">
      {shouldShowImagePreview ? (
        <button
          onClick={handleGoToTakePhoto}
          className="group leading-none gap-2 text-grey-700 flex items-center h-11 px-4 py-3 relative select-none outline-none"
        >
          <img src={retakePhoto} /> <Copy id="photos.upload.retake-photo" />
        </button>
      ) : (
        <>
          <button
            onClick={handleGoToTakePhoto}
            className="group leading-none gap-2 text-grey-700 flex items-center h-11 px-4 py-3 relative select-none outline-none"
          >
            <img src={takePhoto} /> <Copy id="photos.upload.take-photo" />
          </button>
          <button
            onClick={handleOpenCameraRoll}
            className="group leading-none gap-2 text-grey-700 flex items-center h-11 px-4 py-3 relative select-none outline-none"
          >
            <img src={photoLibrary} /> <Copy id="photos.upload.photo-library" />
          </button>
        </>
      )}
    </div>
  );

  return (
    <Popover content={<PopoverContent />} item={<PopoverItem />} align="end" />
  );
};

export default UploadOverviewItem;
